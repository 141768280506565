<template>
  <div class="appCenter">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source>
      <div class="navTitle">应用中心</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="appList">
        <div class="appItem" v-for="item in appList" :key="item.officialImg">
          <ImgDecypt class="appImg" :src="item.officialImg" />
          <div class="appName ellipsis">{{item.officialName}}</div>
          <!-- <div class="appDesc ellipsis">{{item.officialDesc}}</div>
          <div class="download" @click="downloadApp(item.officialUrl)">立即下载</div> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    qyeryOfficial
  } from "@/api/mine";
  import ImgDecypt from "@/components/ImgDecypt";
  export default {
    components: {
      ImgDecypt,
    },
    data() {
      return {
        appList: [],
      }
    },
    created() {
      this.queryList();
    },
    methods: {
      // 返回
      backPage() {
        this.$router.go(-1);
      },
      async queryList() {
        let res = await this.$Api(qyeryOfficial, {
          type: 1
        })
        if (res && res.code == 200) {
          this.appList = res.data || [];
        }
      },
      downloadApp(url) {
        setTimeout(() => {
          window.open(url)
        })
        // location = url
      }
    }
  }
</script>

<style lang="scss" scoped>
  .appCenter {
    color: #000;
    height: 100%;
    background: #f5f5f5;

    .navBar {
      height: 44px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;

      .navArrow {
        width: 17px;
        height: 17px;
      }

      .navTitle {
        flex: 2;
        text-align: center;
        font-size: 18px;
        color: #000;
      }

      .rightBtn {
        width: 17px;
        height: 17px;
      }
    }

    .main {
      height: calc(100% - 44px);
      padding: 0 40px;
      box-sizing: border-box;
      overflow-y: auto;

      .appList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 18px;
        grid-column-gap: 20px;
        margin-top: 18px;
        margin-bottom: 40px;
      }

      .appItem {
        width: 85px;
        text-align: center;

        .appImg {
          width: 85px;
          height: 85px;
          // border-radius: 12px;
          overflow: hidden;

          /deep/ .vanImage {
            background: #00000000 !important;
          }

          /deep/ .van-image__error {
            background-color: #130f3200 !important;
          }
        }

        .appName {
          font-size: 14px;
          margin-top: 8px;
        }

        .appDesc {
          color: #999999;
          font-size: 12px;
        }

        .download {
          width: 76px;
          height: 26px;
          border-radius: 26px;
          line-height: 26px;
          font-size: 12px;
          color: #fff;
          background: #7145e7;
          margin: 12px auto 0;
        }
      }
    }
  }
</style>